/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~bootstrap-icons/font/bootstrap-icons.css';

:root {
	--custom-main-color: #006fba;
	--custom-light-color: #0f99d6;
}

html,
body {
	height: 100vh;
	margin: 0;
	padding: 0;
}

app-root {
	height: 100%;
	display: flex;
	flex-direction: column;
}

body.full-height {
	min-height: 100%;
	margin: 0;
	padding: 0;
}

br {
	height: 20px;
}

app-my-info {
	height: 100%;
}

.remove-padding {
	padding: unset !important;
}

.offcanvas-backdrop.fade.show {
	opacity: 0 !important;
	background-color: transparent;
	position: unset;
}

.xng-breadcrumb-list {
	padding: 21px 16px !important;
	background: unset !important;
}

.xng-breadcrumb-list a {
	font-family: 'Ubuntu';
	font-display: swap;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 25px;
	color: #006fba !important;
}

.xng-breadcrumb-list li:last-child {
	font-family: 'Ubuntu';
	font-display: swap;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 25px;
	color: #6d7278;
}

.xng-breadcrumb-list .xng-breadcrumb-separator {
	content: '' !important;
	display: inline-block !important;
	width: 8px !important;
	height: 8px !important;
	border-style: solid !important;
	border-width: 2px 2px 0 0 !important;
	border-color: #6d7278 !important;
	transform: rotate(45deg) !important;
	margin-left: 9px !important;
	margin-right: 9px !important;
}

/*Font Ubuntu*/
/* BEGIN Ubuntu Light */
@font-face {
	font-family: 'Ubuntu';
	font-display: swap;
	src: url('./assets/fonts/Ubuntu/Ubuntu-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}
/* END Ubuntu Light */

/* BEGIN Ubuntu Light Italic */
@font-face {
	font-family: 'Ubuntu';
	font-display: swap;
	src: url('./assets/fonts/Ubuntu/Ubuntu-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}
/* END Ubuntu Light Italic */

/* BEGIN Ubuntu Regular */
@font-face {
	font-family: 'Ubuntu';
	font-display: swap;
	src: url('./assets/fonts/Ubuntu/Ubuntu-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
/* END Ubuntu Regular */

/* BEGIN Ubuntu Regular Italic */
@font-face {
	font-family: 'Ubuntu';
	font-display: swap;
	src: url('./assets/fonts/Ubuntu/Ubuntu-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}
/* END Ubuntu Regular Italic */

/* BEGIN Ubuntu Medium */
@font-face {
	font-family: 'Ubuntu';
	font-display: swap;
	src: url('./assets/fonts/Ubuntu/Ubuntu-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}
/* END Ubuntu Medium */

/* BEGIN Ubuntu Medium Italic */
@font-face {
	font-family: 'Ubuntu';
	font-display: swap;
	src: url('./assets/fonts/Ubuntu/Ubuntu-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}
/* END Ubuntu Medium Italic */

/* BEGIN Ubuntu Bold */
@font-face {
	font-family: 'Ubuntu';
	font-display: swap;
	src: url('./assets/fonts/Ubuntu/Ubuntu-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}
/* END Ubuntu Bold */

/* BEGIN Ubuntu Bold Italic */
@font-face {
	font-family: 'Ubuntu';
	font-display: swap;
	src: url('./assets/fonts/Ubuntu/Ubuntu-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}
/* END Ubuntu Bold Italic */

/*Font Inter*/
@font-face {
	font-family: 'Inter';
	font-display: swap;
	font-style: normal;
	font-weight: 100;
	src: url('./assets/fonts/Inter/Inter-Thin.ttf') format('truetype');
}

@font-face {
	font-family: 'Inter';
	font-display: swap;
	font-style: normal;
	font-weight: 200;
	src: url('./assets/fonts/Inter/Inter-ExtraLight.ttf') format('truetype');
}

@font-face {
	font-family: 'Inter';
	font-display: swap;
	font-style: normal;
	font-weight: 300;
	src: url('./assets/fonts/Inter/Inter-Light.ttf') format('truetype');
}

@font-face {
	font-family: 'Inter';
	font-display: swap;
	font-style: normal;
	font-weight: 400;
	src: url('./assets/fonts/Inter/Inter-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Inter';
	font-display: swap;
	font-style: normal;
	font-weight: 500;
	src: url('./assets/fonts/Inter/Inter-Medium.ttf') format('truetype');
}

@font-face {
	font-family: 'Inter';
	font-display: swap;
	font-style: normal;
	font-weight: 600;
	src: url('./assets/fonts/Inter/Inter-SemiBold.ttf') format('truetype');
}

@font-face {
	font-family: 'Inter';
	font-display: swap;
	font-style: normal;
	font-weight: 700;
	src: url('./assets/fonts/Inter/Inter-Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'Inter';
	font-display: swap;
	font-style: normal;
	font-weight: 800;
	src: url('./assets/fonts/Inter/Inter-ExtraBold.ttf') format('truetype');
}

@font-face {
	font-family: 'Inter';
	font-display: swap;
	font-style: normal;
	font-weight: 900;
	src: url('./assets/fonts/Inter/Inter-Black.ttf') format('truetype');
}
@media screen and (min-width: 1024px) {
	.root-container {
		padding: 2rem 10% !important;
	}
}
.grid-container {
	display: grid;
	gap: 3rem;
	grid-template-columns: 1fr 1fr;
	grid-template-areas: 'questions nav';
}
.body-content {
	padding-top: 0px;
	height: 100%;
	flex: 1;
	display: flex;
	flex-direction: column;
}
@media screen and (max-width: 768px) {
	.grid-container {
		gap: 1rem;
		grid-template-columns: 1fr;
		grid-template-areas:
			'questions'
			'nav';
	}
	.grid-container-reverse {
		gap: 1rem;
		grid-template-columns: 1fr;
		grid-template-areas:
			'nav'
			'questions';
	}
}

@media screen and (min-width: 768px) {
	.body-content {
		padding-top: 147px;
	}
}

.left-section {
	grid-area: questions;
}
.right-section {
	grid-area: nav;
	display: flex;
	flex-direction: column;
}

.external-link {
	position: relative;
}
.external-link::after {
	content: '     ';
	background-image: url(./assets/icons/external.png);
	background-size: 12px;
	background-position: left;
	background-repeat: no-repeat;
	margin-left: 4px;
	position: absolute;
	width: 14px;
	height: 23px;
	bottom: -3px;
}
li.suggestion-item p {
	margin: 0;
}
li.suggestion-item strong {
	background-color: #0f99d6 !important;
	border-radius: 1px;
	color: white;
	padding: 2px 0px;
}

.base-button {
	font-family: 'Ubuntu' !important;
	font-display: swap;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 16px !important;
	line-height: 18px !important;
	text-align: center !important;
	padding: 24px 15px !important;
	width: 100% !important;
	box-shadow: 0px 1px 0.5px 0px rgba(0, 0, 0, 0.11) !important;
	border-radius: 4px !important;
	-webkit-appearance: none !important;
	-moz-appearance: none !important;
	appearance: none !important;
}
.main-button,
.main-button:hover,
.main-button:active {
	background-color: var(--custom-main-color) !important;
	color: white !important;
}

.outline-button,
.outline-button:hover,
.outline-button:active {
	border: solid 1px var(--custom-main-color) !important ;
	color: var(--custom-main-color) !important;
	background-color: transparent !important;
}

.text-button,
.text-button:hover,
.text-button:active {
	color: var(--custom-main-color) !important;
	border: white 1px solid !important;
	box-shadow: none !important;
}

.slim-button,
.slim-button:hover,
.slim-button:active {
	padding: 10px 15px !important;
}

.onboarding-title-centered {
	text-align: center;
}
